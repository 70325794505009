var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.ready
    ? _c(
        "CCard",
        [
          _c(
            "CCardHeader",
            [_c("MessageDetailHeader", { attrs: { message: _vm.message } })],
            1
          ),
          _c("CCardBody", {
            domProps: { innerHTML: _vm._s(_vm.message.body) }
          }),
          _c("CCardFooter", [
            _vm._v(" Sender: " + _vm._s(_vm.message.sender)),
            _c("br"),
            _vm._v(
              "Gesendet: " +
                _vm._s(_vm.formatIsoDateTimeTz(_vm.message.createdAt)) +
                " (vor " +
                _vm._s(_vm.formatIsoDateTimeDistanceTz(_vm.message.createdAt)) +
                ") "
            )
          ])
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }